@import '/src/styles/sizes';

form {

    .field-group {
        margin-top: 3px;

        &:not(:first-child) {
            margin-top: 5px;

            @media only screen and (max-width: @MIN_DESKTOP_WIDTH) {
                & {
                    margin-top: 1px;
                }
            }
        }

        &.p-field-checkbox {
            margin-top: 10px;
        }

        .p-inputgroup {
            align-items: center;
            justify-content: space-between;

            > :first-child {
                margin-right: 5px;
            }
        }
    }

    @media only screen and (min-width: @MAX_MOBILE_WIDTH) {
        .multiple-fields {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:not(:first-child) {
                margin-top: 5px;
            }

            .field-group {
                margin-top: 0;

                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }
    }

    @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
        .multiple-fields {
            margin-top: 5px;
        }
    }

    .form-field-error {
        color: red;
        font-size: 10pt;
    }

    .p-autocomplete {
        height: 38px;

        .p-button-icon {
            padding-top: 0;
        }
    }
}

.quantity-input input {
    max-width: 100px;
    padding-top: 1px;
    padding-bottom: 1px;
}

.price-input input {
    max-width: 100px;
}

.level-percentage-input {

    .p-dropdown-label {
        padding-top: 4px;
        padding-bottom: 3px;
    }
}
