@import '/src/styles/sizes';
@import 'colors';


// --------------------
//  Header
// --------------------
.navbar {
    padding: 0.5rem 1rem;
}

// --------------------
//  Buttons
// --------------------
.p-button:disabled {
    //.p-button.p-disabled.p-element.p-component:disabled {
    background-color: #light-theme[@button-disabled];
    //    opacity: 0.5;
}

p-button {

    &.p-disabled {
        opacity: 1.0;
    }

    .p-button {
        padding: 0.375rem 0.75rem;

        &.p-button-secondary {
            background-color: #light-theme[@button-secondary];
        }
        //padding: 5px;
    }
}
//button {
//    //border: solid 1px black;
//    font-size: 14px;
//
//    &.dark-text {
//        color: black;
//    }
//
//    &.btn-primary {
//        background-color: #light-theme[@button-primary];
//    }
//    &.btn-secondary {
//        background-color: #light-theme[@button-secondary];
//    }
//    &.btn-success {
//        background-color: #light-theme[@button-success];
//
//        &:disabled {
//            border-color: #light-theme[@button-success];
//        }
//    }
//    &.btn-danger {
//        background-color: #light-theme[@button-danger];
//    }
//    &.btn-warning {
//        background-color: #light-theme[@button-warning];
//    }
//}

.p-dropdown-panel {
    .p-dropdown-items {
        padding-left: 0;
    }
}

.p-listbox {
    .p-listbox-list {
        padding-left: 0;
    }
}

.dark {
    p-button.p-disabled {
        background-color: #dark-theme[@button-disabled] !important;
    }
    //button {
    //    &.btn-primary {
    //        background-color: #dark-theme[@button-primary];
    //    }
    //    &.btn-secondary {
    //        background-color: #dark-theme[@button-secondary];
    //    }
    //    &.btn-success {
    //        background-color: #dark-theme[@button-success];
    //    }
    //}
}

.pi-spinner {
    color: #light-theme[@primary-color];
}

// --------------------
//  Tabs and menu bar
// --------------------

.p-menubar {
    background-color: transparent;

    a {
        text-decoration: none;
    }
}

.sub-tabs {
    display: flex;
    flex-direction: column;

    .p-tabmenu-nav {
        flex-grow: 1;
        flex-shrink: 1;
        display: flex;
        flex-wrap: nowrap;
        background-color: transparent;
        border: none;
        padding-left: 2px;

        @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
            justify-content: space-between;
        }

        .p-tabmenuitem { // LI = list item
            flex-shrink: 1;
            //max-width: 25%;

            @media only screen and (min-width: @MAX_MOBILE_WIDTH) {
                margin-right: 20px;
            }

            &.p-highlight { // A (active) = active link
                color: #light-theme[@sub-tab-text-color];

                .p-menuitem-link { // A = link
                    background-color: #light-theme[@sub-tab-background-color-active];
                }
            }

            .p-menuitem-link { // A = link
                height: 36px;
                width: 92px;
                padding-left: 8px;
                padding-right: 8px;
                color: #light-theme[@sub-tab-text-color];
                font-size: 12px;
                font-weight: 300;
                justify-content: center;
                border-radius: 5px;
                border: solid 1px #light-theme[@sub-tab-border-color];
                background-color: #light-theme[@sub-tab-background-color];
                text-decoration-line: none;

                .p-menuitem-text {
                    text-align: center;
                }

                @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                    padding-left: 3px;
                    padding-right: 3px;
                    width: 75px;
                }
            }

            &:hover {
                .p-menuitem-link {
                    background-color: #light-theme[@sub-tab-background-color-hover] !important;
                    &.p-menuitem-link-active { // A (active) = active link
                        background-color: #light-theme[@sub-tab-background-color-active-hover] !important;
                    }
                }
            }
        }

        .p-tabmenu-ink-bar {
            display: none;
        }
    }
}

.p-active-submenu {
    padding-left: 2px;
    a {
        text-decoration: none;
    }

    &.p-submenu-list {
        margin-left: -30px;
    }
}

// --------------------
//  Tables and data views
// --------------------
p-table {
    flex-grow: 1;
    background-color: white;

    .p-datatable {

        .p-datatable-table.stacked {

            @media screen and (max-width: 960px) {
                & > .p-datatable-thead > tr > th,
                & > .p-datatable-tfoot > tr > td {
                    display: none !important;
                }

                & > .p-datatable-tbody > tr > td {
                    display: flex;
                    width: 100% !important;
                    align-items: center;
                    justify-content: space-between;
                }

                & > .p-datatable-tbody > tr > td:not(:last-child) {
                    border: 0 none;
                }

                #pn_id_23.p-datatable-gridlines > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tbody > tr > td:last-child {
                    border-top: 0;
                    border-right: 0;
                    border-left: 0;
                }

                & > .p-datatable-tbody > tr > td > .p-column-title {
                    display: block;
                }
            }
        }

        .p-datatable-tbody tr td {
            overflow: hidden;
        }

        &.p-datatable-hoverable-rows {
            .p-datatable-tbody > tr:not(.p-highlight):hover {
                background: #light-theme[@primary-medium];
            }
        }

        table {
            background-color: white;

            .p-datatable-tbody > tr.p-highlight {
                background: #light-theme[@primary-medium];
                color: #light-theme[@text-color-inverse];
            }

            .p-datatable-thead {
                & > tr > th {
                    padding-top: .2rem;
                    padding-bottom: .3rem;
                    border: #light-theme[@table-border];
                }

                & > tr:not(.p-highlight) > th {
                    background-color: #light-theme[@table-header-background];
                    color: #light-theme[@table-header-text-color];
                }

                .p-column-filter-menu-button-active, .p-column-filter-menu-button:hover {
                    background-color: #light-theme[@table-header-selected-background-color];
                }
            }

            .p-datatable-tfoot > tr:not(.p-highlight) > td,
            .p-datatable-tbody {

                & > tr > td {
                    border: #light-theme[@table-border];
                }

                & > tr:not(.p-highlight) > td {
                    background-color: #light-theme[@table-dark-cell-color];
                    color: #light-theme[@text-color];

                    button {
                        color: #light-theme[@text-color];
                    }
                }
            }

            .p-datatable-tbody > tr.even:not(.p-highlight) > td {
                background-color: #light-theme[@table-light-cell-color];
                color: #light-theme[@text-color];
            }

            .p-sortable-column {
                &.p-highlight {
                    background-color: #light-theme[@primary-medium];

                    &:hover {
                        background-color: #light-theme[@primary-medium];

                        .p-sortable-column-icon {
                            color: #light-theme[@table-header-text-color] !important;
                        }
                    }
                }

                &:not(.p-highlight):hover {
                    background-color: #light-theme[@button-primary];
                    color: #light-theme[@table-header-text-color] !important;
                }
            }

            .p-sortable-column-icon, .pi-filter-icon {
                color: #light-theme[@table-header-text-color] !important;
            }

            @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                .p-datatable-thead > tr > th,
                .p-datatable-tfoot > tr > td {
                    //display: none !important;
                }

                .p-datatable-tbody > tr > td {
                    border: 0 none;
                    padding: 3px;
                    overflow-wrap: anywhere;

                    .p-column-title {
                        padding: .4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -.4em 1em -.4em -.4rem;
                        font-weight: bold;
                    }

                    //&:last-child {
                    //    border-bottom: 1px solid var(--surface-d);
                    //}
                }
            }
        }
    }
}


.dark {
    //.p-datatable {
    //
    //    .p-datatable-scrollable-header {
    //        background: none;
    //    }
    //
    //    .p-datatable-tbody > tr.p-highlight {
    //        background: #dark-theme[@primary-medium];
    //    }
    //
    //    .p-datatable-thead > tr:not(.p-highlight) > th,
    //    .p-datatable-tfoot > tr:not(.p-highlight) > td,
    //    .p-datatable-tbody > tr:not(.p-highlight) > td {
    //        //background-color: #dark-theme[@main-background-color];
    //        color: #dark-theme[@text-color];
    //
    //        button {
    //            color: #dark-theme[@text-color];
    //        }
    //    }
    //
    //    .p-sortable-column {
    //        .p-sortable-column-icon {
    //            color: #dark-theme[@text-color];
    //        }
    //
    //        &.p-highlight {
    //            background-color: #dark-theme[@primary-medium] !important;
    //
    //            &:hover {
    //                background-color: #dark-theme[@primary-medium];
    //
    //                .p-sortable-column-icon {
    //                    color: #dark-theme[@text-color];
    //                }
    //            }
    //        }
    //        &:not(.p-highlight) {
    //
    //            &:hover {
    //                background-color: #dark-theme[@button-primary];
    //                .p-sortable-column-icon {
    //                    color: #dark-theme[@text-color];
    //                }
    //            }
    //        }
    //    }
    //
    //    .p-datatable-tbody > tr.even:not(.p-highlight) > td {
    //        //background-color: #dark-theme[@primary-light];
    //        color: #dark-theme[@text-color-inverse];
    //    }
    //}

    .p-dataview-emptymessage {
        color: #dark-theme[@text-color];
    }
}

// --------------------
//  Trees
// --------------------
.p-tree {
    background-color: #light-theme[@main-background-color];
    color: #light-theme[@text-color];

    .p-tree-container {
        padding-left: 2px;

        .p-treenode .p-treenode-content {

            .p-tree-toggler, .p-treenode-icon {
                color: #light-theme[@text-color];

                &:enabled:hover {
                    color: #light-theme[@hover-color];
                }
            }

            &.p-treenode-selectable:not(.p-highlight):hover {
                color: #light-theme[@hover-color];
            }

            &.p-highlight {
                background-color: #light-theme[@primary-light];
                color: #light-theme[@primary-dark];

                .p-tree-toggler, .p-treenode-icon {
                    color: #light-theme[@primary-dark];
                }
            }
        }
    }
}

.dark {
    .p-tree {
        background-color: #dark-theme[@main-background-color];
        color: #dark-theme[@text-color];

        .p-tree-container .p-treenode .p-treenode-content {

            .p-tree-toggler, .p-treenode-icon, .p-treenode-label {
                color: #dark-theme[@text-color];
            }

            &:hover {
                .p-tree-toggler, .p-treenode-icon, .p-treenode-label {
                    color: #dark-theme[@hover-color];
                }
            }

            &.p-highlight {
                .p-tree-toggler, .p-treenode-icon, .p-treenode-label {
                    color: #dark-theme[@text-color-inverse];
                }
            }
        }
    }
}


// --------------------
//  Dialogs
// --------------------
.p-dialog-top {
    margin-top: 5rem;
}

.p-dialog {

    .p-dialog-header {
        background-color: #light-theme[@dialog-header-color];
        font-size: 24px;
    }

    .p-dialog-content {
        padding: 20px;
        background-color: #light-theme[@dialog-content-color];
        color: #light-theme[@text-color];
    }

    .p-dialog-footer {
        padding: 20px;
        background-color: #light-theme[@dialog-footer-color];

        button:not(:first-child) {
            margin-left: 5px;
        }
    }
}

.p-confirm-dialog-message, .p-toast-detail {
    white-space: pre-line;
}

.dark {
    .p-dialog {

        .p-dialog-header {
            background-color: #dark-theme[@dialog-header-color];
        }

        .p-dialog-content {
            background-color: #dark-theme[@dialog-content-color];
            color: #dark-theme[@text-color];
        }

        .p-dialog-footer {
            background-color: #dark-theme[@dialog-footer-color];
        }
    }
}

// --------------------
//  Input fields
// --------------------
.p-inputtext {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.p-inputgroup {
    //align-items: center;

    > span {
        flex-shrink: 0;
    }

    .p-dropdown {
        width: auto;
    }

    input {
        margin-left: 3px !important;
    }

    span:not(.p-calendar):not(.p-inputnumber-button-group) {
        padding-top: 5px;
    }
}

.p-field-checkbox, .p-field-radioButton {
    label {
        margin-bottom: 0;
    }
}

.p-checkbox-label {
    margin-bottom: 0;
}

form {

    .multiselect-listbox {
        margin-top: 10px;
    }

    .p-field-checkbox {
        margin-top: 8px;
    }

    .form-input-field-error {
        .p-message {
            .p-message-wrapper {
                padding: 5px 3px;
            }
        }
    }
}

.dark {
    .edit-area-dialogs, .input-area {

        .p-checkbox-label {
            color: #dark-theme[@text-color];
        }

        button {
            i.fa, i.far, i.fas {
                color: #dark-theme[@text-color];
            }
        }
    }

    .p-inputtext {
        background-color: #dark-theme[@input-background-color];
        color: #dark-theme[@input-text-color];
        border: none;

        &::placeholder { color: #dark-theme[@input-placeholder-color]; }
        &::-webkit-input-placeholder { color: #dark-theme[@input-placeholder-color]; }
        &::-ms-input-placeholder { color: #dark-theme[@input-placeholder-color]; }

        &.light-background {
            background-color: white;
            color: black;
        }
    }

    .p-listbox {
        background-color: #dark-theme[@input-background-color];
        color: #dark-theme[@input-text-color];

        .p-listbox-item {
            color: #dark-theme[@input-text-color];
        }
    }

    .p-calendar {
        input {
            background-color: #dark-theme[@input-background-color];
            color: #dark-theme[@input-text-color];
        }

        input[readonly]::placeholder {
            color: #dark-theme[@input-text-color];
        }

        .p-datepicker {
            background-color: #dark-theme[@primary-dark];
            color: #dark-theme[@text-color];

            .p-datepicker-header {
                background-color: #dark-theme[@primary-dark];
                color: #dark-theme[@text-color];

                button {
                    color: #dark-theme[@text-color];

                    &:hover {
                        color: #dark-theme[@primary-light];
                    }
                }
            }

            table {
                th {
                    color: #dark-theme[@primary-light];
                }
                td {
                    .p-highlight {
                        background-color: #dark-theme[@primary-light];
                        color: #dark-theme[@text-color-inverse]
                    }
                }
            }
        }
    }

    .p-dropdown {
        background-color: #dark-theme[@dropdown-item-background-color];
        color: #dark-theme[@dropdown-item-text-color];

        .p-dropdown-trigger {
            color: #dark-theme[@text-color];
        }

        .p-inputtext, .p-dropdown-trigger-icon {
            color: #dark-theme[@dropdown-item-text-color];
        }

        &.light-background {
            background-color: white;
            color: black;

            .p-inputtext {
                background-color: white;
                color: black;
            }

            .p-dropdown-trigger {
                color: black;
            }

            .p-inputtext, .p-dropdown-trigger-icon {
                color: black;
            }
        }
    }

    .p-multiselect, .p-multiselect-panel {
        background-color: #dark-theme[@dropdown-item-background-color];

        .p-multiselect-label {
            color: #dark-theme[@text-color-inverse];

            &.p-placeholder {
                color: #dark-theme[@input-placeholder-color];
            }
        }

        .p-multiselect-trigger {
            color: #dark-theme[@dropdown-item-text-color];
        }

        .p-multiselect-items {
            .p-multiselect-item, .p-multiselect-item-group {
                background-color: transparent;
                color: #dark-theme[@dropdown-item-text-color];

                &:hover
                {
                    background: rgba(0, 0, 0, 0.04);
                }
            }
        }

        &.light-background {
            background-color: white;
            color: black;
            .p-multiselect-trigger {
                color: black;
            }
        }
    }

    .p-dropdown-panel, &.p-dropdown-panel {
        background-color: #dark-theme[@dropdown-item-background-color];
        border: #dark-theme[@dropdown-item-border];

        .p-dropdown-header {
            background-color: transparent;
        }

        .p-dropdown-items {
            .p-dropdown-item, .p-dropdown-item-group {
                background-color: transparent;
                color: #dark-theme[@dropdown-item-text-color];

                &.p-highlight {
                    color: #dark-theme[@dropdown-item-highlight-text-color];
                }

                &.p-focus {
                    &:not(.p-highlight) {
                        background-color: #dark-theme[@dropdown-item-focus-background-color];
                    }
                }

                &:hover
                {
                    background: rgba(0, 0, 0, 0.04);
                }
            }
        }
    }
}

// --------------------
//  Menus and dropdowns
// --------------------
.p-menu-list, .p-tieredmenu-root-list {
    padding-left: 2px;

    .p-menuitem {
        .p-menuitem-content {
            .p-menuitem-link {
                text-decoration-line: none;
            }
        }
    }
}


@media only screen and (max-width: @MAX_MOBILE_WIDTH) {
    .p-tieredmenu {
        .p-submenu-list {
            left: initial !important;
            top: 50px !important;
        }
    }
}

.p-multiselect-items {
    padding-left: 2px;
}

.dark {
    .p-menu, .p-slidemenu, .p-submenu-list, .p-menu-list {
        padding-left: 3px;
        //background-color: #dark-theme[@dropdown-item-background-color];
        //color: #dark-theme[@dropdown-item-text-color];

        .p-menuitem-text, .p-submenu-icon {
            //color: #dark-theme[@dropdown-item-text-color];
        }
    }

    .p-menuitem-content:hover {
        //background-color: #light-theme[@hover-color];
    }
}

// --------------------
//  File Upload
// --------------------
.p-fileupload {
    .p-fileupload-buttonbar {
        border: none;
        background: transparent;
    }
}

@media only screen and (max-width: @MIN_DESKTOP_WIDTH) {
    th, td {
        font-size: @SMALL_FONT_SIZE;

        .pi-filter-icon, .p-sortable-column-icon {
            font-size: @SMALL_FONT_SIZE;
        }
    }

    .scaled-dialog {
        .p-inputgroup {
            span {
                font-size: @SMALL_FONT_SIZE;
            }
        }

        .p-radiobutton-label {
            font-size: @SMALL_FONT_SIZE;
        }

        .p-datatable .p-datatable-tbody tr td {
            padding-top: 6px;
            padding-bottom: 6px;
        }
    }

    .p-toast {
        max-width: 95%;

        &.p-toast-bottom-right {
            right: 5px;
        }
    }
}

.scrollable-table {
    max-height: 100%;
    overflow: auto;
}
