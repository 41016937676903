@import '/src/styles/sizes';
@import 'colors';

.dashboard-widget-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #light-theme[@widget-default-font-color];

    .widget-highcharts-chart {
        opacity: 0;

        &.fade-in-chart {
            transition: all 0.5s;
            opacity: 1;
        }
    }

    //.widget-link-button-host {
    //    position: relative;
    //}
    //
    //.widget-link-button-container {
    //    position: absolute;
    //    top: 5px;
    //    right: 5px;
    //    height: @WIDGET_LINK_BUTTON_HEIGHT_AND_WIDTH;
    //    width: @WIDGET_LINK_BUTTON_HEIGHT_AND_WIDTH;
    //    border: 1px solid lightgray;
    //    border-radius: 4px;
    //    background-color: @widget-link-button-background-color;
    //    cursor: pointer;
    //    display: flex;
    //    justify-content: center;
    //    align-items: center;
    //    overflow: hidden;
    //    z-index: 1;
    //
    //    img {
    //        width: 22px;
    //    }
    //
    //    &:hover {
    //        background-color: darken(@widget-link-button-background-color, 5%);
    //
    //        &:active {
    //            background-color: darken(@widget-link-button-background-color, 10%);
    //        }
    //    }
    //}

    .dashboard-widget-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: @dashboard-widget-header-height;
        padding: @dashboard-widget-header-padding;
        color: #light-theme[@dashboard-widget-header-color];
        font-size: @dashboard-widget-header-font-size;
        border-bottom: 1px solid #light-theme[@dashboard-widget-header-border-color];
        cursor: move;

        &.widget-non-draggable-area {
            cursor: default;
        }

        .widget-header-icon-n-title {
            flex: none;
            display: flex;

            .widget-header-icon {
                margin-right: @dashboard-widget-header-icon-right-margin;
            }
        }

        .widget-header-middle-section {
            &.widget-non-draggable-area {
                cursor: default;
            }
        }

        .widget-header-toolbar {
            display: flex;

            .widget-header-toolbar-menu {
                cursor: pointer;
            }

            .widget-toolbar-button {
                padding: @dashboard-widget-header-toolbar-button-padding;
                color: lighten(#light-theme[@dashboard-widget-header-toolbar-button-color], 60%);

                &:hover {
                    color: lighten(#light-theme[@dashboard-widget-header-toolbar-button-color], 40%);
                    cursor: pointer;
                }

                &:active {
                    color: #light-theme[@dashboard-widget-header-toolbar-button-color];
                }
            }
        }
    }

    .dashboard-widget-content {
        position: relative;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        margin-top: 3px;
        padding: @dashboard-widget-content-padding;
        opacity: 0;

        &.fade-in-on-load {
            transition: opacity 1s;
            opacity: 1;
        }

        .widget-link {
            width: 100%;
            display: flex;
            padding-right: 20px;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 12px;
            z-index: 1;

            a {
                color: #333;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            &.is-disabled {
                cursor: default;
                opacity: 0.5;
            }
        }

        .widget-content-pane {
            flex: 1;
            display: flex;
            transition: opacity 0.5s;

            &.fade-on-resize {
                opacity: 0;
            }

            .widget-link {
                flex: 1;
            }
        }
    }

    .dashboard-widget-footer {
        display: flex;
        align-items: center;
        color: #light-theme[@dashboard-widget-footer-color];
        font-size: @dashboard-widget-footer-font-size;
        margin: @dashboard-widget-footer-margin;
        min-height: @dashboard-widget-footer-min-height;

        .fa {
            color: #light-theme[@dashboard-widget-alert-banner-background-color];
            margin: 0 5px;
            z-index: 3;
            cursor: pointer;
        }
    }
}

.widget-alert-tooltip {
    &.ui-tooltip {
        max-width: 300px;
    }

    // tooltip background color.
    .ui-tooltip-text.ui-shadow.ui-corner-all {
        background-color: #light-theme[@dashboard-widget-alert-banner-background-color];
    }
}
