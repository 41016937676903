@import '/src/styles/sizes';
@import 'colors';

.tooltiptext.mac.dark {
    color: white;
}

.popup-content-container {
    .tooltiptext {
        visibility: hidden;
    }
}

.map-vendor-popup {
    padding: 5px 10px;
    color: black;

    > div {
        line-height: 1.2;
        font-size: 16px;
        margin-left: 10px;

        @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
            font-size: 13px;
            margin-left: 5px;
            //line-height: 1;
        }
    }

    .name {
        font-size: 24px;
        font-weight: bold;
        text-decoration: underline;
        margin-left: 0;

        @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
            font-size: 16px;
        }
    }

    .section-header {
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 0;
        font-size: 18px;
        font-weight: bold;

        @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
            font-size: 14px;
            margin-top: 6px;
            margin-bottom: 2px;
        }
    }

    .contact {
        .contact-name {
            font-weight: bold;
        }

        .contact-email, .contact-phone {
            margin-left: 10px;
        }
    }
}
