@import '/src/styles/sizes';

.user-log-dialog {
    .p-dialog-content {
        display: flex;
        flex-direction: column;
        padding: 10px;

        .label {
            font-size: 30px;
            @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                font-size: 18px;
            }
        }

        .input-line {
            margin-top: 10px;
            display: flex;
            align-items: center;
            width: 100%;
            @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                width: 90vw;
            }

            > :not(:first-child) {
                margin-left: 5px;
                flex-grow: 1;
            }

            .follow-up-input {
                max-width: 100px;
            }

            input, .p-dropdown {
                width: 100%;
            }
        }

        .log-area {
            display: flex;
            flex-direction: column;

            .description {
                width: 400px;
                @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                    width: 90vw;
                }
                font-size: 14px;
            }

            textarea {
                width: 500px;
                @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                    width: 90vw;
                }
            }
        }
    }

    .p-dialog-footer {
        display: flex;
        justify-content: space-between;
    }
}
