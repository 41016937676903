@import '/src/styles/sizes';
@import 'colors';

.navbar {

    &.navbar-light {
        background-color: #light-theme[@navbar-background];
    }

    &.navbar-dark {
        background-color: #dark-theme[@navbar-background];
    }

    @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
        padding: 3px 10px;

        .navbar-brand {
            font-size: 16px;
        }
    }

    .navbar-collapse.show {
        .nav-item {
            a {
                font-size: @SMALL_FONT_SIZE;
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
    }
}
