@import '/src/styles/sizes';
@import 'colors';

.manufacturer-name, .part-type {
    color: black;
    font-style: italic;
    font-size: 24px; // Overridden if in grid
}

.nicknames {
    color: black;
    font-style: italic;
    font-size: 24px; // Overridden if in grid

    @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
        font-size: 20px;
    }
}

.part-number {
    font-size: 28px; // Overridden if in grid
    font-weight: 600;
    color: #light-theme[@text-color-manufacturer];
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
}

.price {
    font-size: 24px; // Overridden if in grid
    color: #light-theme[@text-color-price];
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    .in-testing {
        color: #light-theme[@text-color-in-testing];
    }
}

.favorite {
    cursor: pointer;

    .fa {
        color: gold;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: black;
    }

    .far {
        color: black;
    }
}

.mobile-table {
    color: #light-theme[@text-color];

    .card {
        background-color: #light-theme[@grid-item-background-color];
    }
}

.right-side-container {
    color: #light-theme[@text-color];
}

.dark {
    .right-side-container {
        color: #dark-theme[@text-color];
    }
}
