@import '/src/styles/sizes';
@import 'colors';

.tree-left-side {
    border-right: solid 1px lightgray;

    &.mobile {
        width: 100%;
    }

    .tree {
        border: none;
        border-radius: 0;

        .p-treenode {
            white-space: nowrap;
        }
    }
}

.right-side-top-level {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #light-theme[@main-background-color];

    &.allow-full-scroll {
        .right-side-container {
            overflow: auto;
        }
    }

    .add-button {
        margin: 5px;

        button:not(:first-child) {
            margin-left: 10px;
        }
    }

    .back-button {
        flex-shrink: 0;
        margin-top: 3px;
    }

    .right-side-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;

        .edit-area {
            display: flex;
            flex-direction: column;
            align-items: center;
            @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                overflow-y: scroll;
            }

            .edit-area-header {
                flex-shrink: 0;
                font-size: 20px;
                font-weight: bold;
                margin: 0 auto;
                padding-top: 3px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;

                @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                    font-size: 16px;
                }
            }

            .edit-area-dialogs {

                display: flex;
                flex: 1 0 auto;
                width: 100%;
                padding: 0;
                justify-content: center;
                max-height: 475px;

                @media only screen and (min-height: 800px) {
                    max-height: 600px;
                }

                @media only screen and (min-height: 1100px) {
                    max-height: 800px;
                }

                @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                    flex-wrap: wrap;
                    max-height: inherit;
                    flex: 0 1 auto;
                }

                > div {
                    background-color: #light-theme[@form-background-color];

                    @media only screen and (min-width: @MAX_MOBILE_WIDTH) {
                        &:not(:first-child) {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }
    }
}

.mobile-list-with-search, .mobile-list-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    &:not(.mobile-list-container) {
        padding-top: 5px;
    }

    .mobile-search {
        padding: 3px;

        span {
            width: 100%;

            input {
                width: 100%;
            }
        }
    }

    .mobile-table {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; // Safari

        .card {
            margin-top: 5px;
            margin-left: 5px;
            margin-right: 5px;
            padding: 5px;
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            box-shadow: 3px 3px gray;

            .mobile-row {
                font-size: @SMALL_FONT_SIZE;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .truncate {
                    max-width: 46%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.desktop-table-container {
    display: flex;
    flex-direction: column;
    flex-grow: 100;
    overflow: hidden;
    //background-color: bisque;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.inner-table-container {
    overflow: hidden;
    display: flex;
    flex: 1;
}

.watermark-component {
    position: relative;

    .watermark-container {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        pointer-events: none;

        .watermark-image {
            max-height: calc(60vh);
            max-width: calc(90vw);
            opacity: 0.2;
        }
    }
}

.dark {
    .right-side-top-level {
        background-color: #dark-theme[@main-background-color];

        .right-side-container {
            .edit-area {
                .edit-area-dialogs {
                    > div {
                        background-color: #dark-theme[@form-background-color];
                    }
                }
            }
        }
    }
}
