@import '/src/styles/sizes';
@import 'colors';

@SHORT_SIGNATURE_BLOCK: 60px;
@TALL_SIGNATURE_BLOCK: 100px;

.po-pdf, .hedge-pdf {
    display: flex;
    flex-direction: column;

    .header {
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;

        @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
            font-size: 14px;
        }
    }

    .intro {
        .intro-bold {
            font-weight: bold;
            margin-right: 5px;
        }
    }

    .po-info {
        margin-top: 20px;

        table {
            width: 60%;
            max-width: 600px;
            margin: 0 auto;

            tr.bordered-row {
                border: 1px solid black;

                td {
                    border: 1px solid black;
                    background-color: white;
                }
            }
        }

        .initials-paragraph {

            &.indented {
                margin-left: 60px;
            }

            .initials-line {
                display: flex;

                @media only screen and (min-width: @MAX_MOBILE_WIDTH) {
                    align-items: flex-end;
                }

                :first-child {
                    min-width: 60px;
                    max-width: 60px;
                    flex-shrink: 1;
                    height: @SHORT_SIGNATURE_BLOCK;
                    overflow: hidden;
                    display: flex;
                }

                div:not(:first-child) {
                    margin-left: 5px;
                }
            }
        }

        .divider {
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            height: 3px;
            background-color: black;
        }
    }

    .multi-signature-line {
        display: flex;

        .signature-auto-expand {
            flex-grow: 1;
            display: flex;
        }

        @media only screen and (min-width: @MAX_MOBILE_WIDTH) {
            height: @SHORT_SIGNATURE_BLOCK;

            &.tall {
                height: @TALL_SIGNATURE_BLOCK;
            }

            .text {
                align-self: flex-end;
            }

            > :not(:first-child) {
                margin-left: 10px;
            }
        }

        @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
            flex-direction: column;

            .capture-signature {
                height: @SHORT_SIGNATURE_BLOCK;
            }

            &.tall {
                .capture-signature {
                    height: @TALL_SIGNATURE_BLOCK;
                }
            }
        }
    }

    .signature-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .capture-signature {
            width: 100%;
            height: @SHORT_SIGNATURE_BLOCK;
        }

        &.tall {
            .capture-signature {
                height: @TALL_SIGNATURE_BLOCK;
            }
        }

        @media only screen and (min-width: @MAX_MOBILE_WIDTH) {

            &.half {
                width: 50%;
            }

            &.right {
                margin-left: 50%;
            }
        }

    }

    //.capture-signature {
    //    display: flex;
    //    align-items: stretch;
    //}

    .buttons {
        margin-top: 10px;
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-between;
        align-self: center;

        button:not(:first-child) {
            margin-left: 10px;
        }
    }
}

.dark {
    .po-pdf {
        color: #dark-theme[@text-color];

        .po-info {
            table {
                color: #dark-theme[@text-color-inverse];

                th {
                    color: #dark-theme[@text-color];
                }
            }
        }
    }
}
