@import "../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "primeng-theme";
@import "../../node_modules/primeng/resources/primeng.css";
@import "./bootstrap-overrides";
@import "./primeng-overrides";
@import "./azure-map-overrides";
@import "./dashboard";
@import "./forms";
@import "./dialogs";
@import "./reports";
@import "./pdf";
@import "./component-layout";
@import "./text-elements";

.component-name {
    display: none;
    margin-top: 2px;
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 100;
    color: red;
}

.tab-body-container {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.role-icons {
    font-size: 14px;
    i:not(:first-child) {
        margin-left: 3px;
    }
}

// "Clear search" button on the right side of search fields
input[type="search"]::-webkit-search-cancel-button {

    /* Remove default */
    -webkit-appearance: none;

    /* Now your own custom styles */
    height: 14px;
    width: 14px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
    /* setup all the background tweaks for our custom icon */
    background-repeat: no-repeat;

    /* icon size */
    background-size: 14px;

}

.no-data {
    padding: 20px;
    font-size: 20px;
    color: red;
    flex-shrink: 0;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
