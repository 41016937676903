@import '/src/styles/sizes';

.reports {
    .input-area {
        display: flex;
        justify-content: center;
        width: 100%;
        flex-shrink: 0;

        form {
            display: flex;
            flex-shrink: 0;
            padding: 1px;

            @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                flex-direction: column;
            }

            .input-line {
                display: flex;
                align-items: center;
                margin-top: 5px;

                @media only screen and (max-width: @MAX_MOBILE_WIDTH) {
                    margin-top: 2px;
                }

                .field-group {
                    margin-top: 0;
                }

                &:not(:first-child), > :not(:first-child) {
                    margin-left: 10px;
                }

                span {
                    padding: 2px 5px;
                }

                button {
                    margin-left: 3px !important;
                }
            }

            input {
                width: 120px;
            }

            button {
                margin-left: 0;
                white-space: nowrap;
            }
        }
    }

    .p-datatable-tbody > tr > td {
        padding-top: 1px;
        padding-bottom: 1px;

        //div {
        //    overflow: hidden;
        //    white-space: nowrap;
        //    text-overflow: ellipsis;
        //}
    }

    .card.selected {
        background-color: #light-theme[@primary-medium];
    }

    .chart-area {
        flex-grow: 1;
        flex-shrink: 0;

        display: flex;
        flex-direction: column;
        width: 100%;

        .chart {
            width: 100%;
            display: block;
            margin-top: 10px;
        }
    }

    .placeholder-for-safari-rendering-issues {
        flex-grow: 1;
        width: 100%;
    }
}
